<template>
    <div class="property">
        <div v-if="tag !== 'none'" class="tag">
            <p>{{tag | startCase}}</p>
        </div>
        <router-link :to="'/property/'+slug">
            <img v-lazy="imgURL ? imgURL : placeholderImage" :alt="id" class="propertyImg" >
        </router-link>
        <router-link class="propertyDetail" :to="'/property/'+slug">
            <span class="address">{{address}} </span>
            <span class="dot"> &#183; </span>
            <span class="price">{{price | priceFormat}}{{leased}} </span>
        </router-link>
    </div>
</template>

<script>
export default {
    name: 'PropertyListing',
    props: ['id','tag', 'imgURL', 'address', 'price', 'slug', 'lease'],
    filters: {
        priceFormat: function (value) {
            if (!value) return ''
            const formatter = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
                minimumFractionDigits: 0
            })
            
            return formatter.format(value)
        }
    },
    computed:{
        leased: function() {
            if(this.lease){
                return "/mo.";
            }
            return '';
        }
    },
    data(){
        return{
            placeholderImage: "/images/placeholder.png",
        }
    }

}
</script>

<style lang="scss" scoped>
.propertyDetail{
    display: block;
    padding: 0.75rem 0;
    text-decoration: none;
    color:#000;
}

.property{
    flex: 0 0 50%;
    max-width: 50%;
    position: relative;
    width: 100%;
}

.propertyImg{
    height: 420px;
    width: 100%;
    object-fit: cover;
}

.address{
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 2px;
    font-size: 1.05rem;
}

.price{
    color: #4c4c4c;
    font-size: 1.05rem;
    letter-spacing: 3px;
}

.tag{
    z-index: 2;
    position: absolute;
    width: 200px;
    color:white;
    background: $primaryColor;
    text-align: center;
    text-transform: uppercase;
    top: -19.5px;
    left: 39px;
    p{
        margin: 0.5rem auto;
        font-size: 1.2rem;
        letter-spacing: 3px;
    }
}

@media (max-width: 800px) { 
    .propertyImg{
        height: 360px;
    }
}
@media (max-width: $mobile) { 
    .property{
        width: 100%;
        max-width: 100%;
        padding: 1.5rem 0;
    }
    .tag{
        position: absolute;
        width: 160px;
        color:white;
        background: $primaryColor;
        text-align: center;
        text-transform: uppercase;
        left: 20px;
        p{
            margin: 0.5rem auto;
            font-size: 0.9rem;
            letter-spacing: 2px;
        }
    }
    .propertyImg{
        height: 260px;
        width: 100%;
        object-fit: cover;
    }
    .propertyDetail{
        text-align: center;
        .address{
            letter-spacing: 1.2px;
            font-size: 1.1rem;
            display: block;
        }

        .dot{
            display:none;
        }

        .price{
            display: block;
            font-size: 1.1rem;
            letter-spacing: 2px;
        }
    }
}
</style>

